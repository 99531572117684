/* istanbul ignore file */
// @todo tymofiih@wix.com research enzyme render issue

import React from 'react';
import {Button} from 'wix-ui-tpa';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import * as BackInStockButtonStyles from './BackInStockButton.scss';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {classes as addToCartButtonClasses} from '../AddToCartButton/AddToCartButton.st.css';
import {classes as buyNowButtonClasses} from '../BuyNowButton/BuyNowButton.st.css';
import classNames from 'classnames';

interface BackInStockButtonInterface extends ProvidedGlobalProps, IProvidedTranslationProps {
  className?: string;
}

interface BackInStockButtonState {
  showError: boolean;
}

export enum DataHook {
  Root = 'BackInStockButton.Root',
}

@withGlobalProps
@withTranslations('globals.texts')
export class BackInStockButton extends React.Component<BackInStockButtonInterface, BackInStockButtonState> {
  private createNotification() {
    this.props.globals.handleNotifyWhenBackInStock();
  }

  private getClasses() {
    const {shouldShowWishlistButton, shouldShowAddToCartButton, shouldShowBuyNowButton} = this.props.globals;
    return classNames({
      [addToCartButtonClasses.addToCartButton]: shouldShowAddToCartButton,
      [buyNowButtonClasses.buyNowButton]: !shouldShowAddToCartButton && shouldShowBuyNowButton,
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlistButton,
      [this.props.className]: true,
      [BackInStockButtonStyles.fullWidth]: !shouldShowWishlistButton,
      [BackInStockButtonStyles.backInStockButton]: true,
    });
  }

  public render() {
    return (
      <Button
        data-hook={DataHook.Root}
        onClick={() => {
          this.createNotification();
        }}
        className={this.getClasses()}>
        {this.props.t('productPage.backInStock.notifyWhenAvailable.button')}
      </Button>
    );
  }
}
