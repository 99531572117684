import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import s from './PreOrderMessage.scss';

@withGlobalProps
export class PreOrderMessage extends React.Component<ProvidedGlobalProps> {
  public render(): JSX.Element {
    const {
      product: {inventory},
    } = this.props.globals;
    return (
      <div className={s.preOrderMessage} data-hook="pre-order-message">
        {inventory.preOrderInfoView.message}
      </div>
    );
  }
}
