import * as React from 'react';
import {IImageDimension} from '../../../../types/productDef';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {IMediaItem} from '../../../../types/app-types';
import {ImageLoadingBehaviorOptions, ImageResizeOptions, WowImage} from 'wix-ui-tpa';

export interface ProductImageItemProps extends ProvidedGlobalProps {
  mediaItem: IMediaItem;
  imageMode: ImageMode;
  productName: string;
  dimensions?: IImageDimension;
  targetHeight?: number;
  targetWidth?: number;
  imageLoaded?(): any;
  aspectRatio?: number;
  fluid?: boolean;
  stretchImage?: boolean;
}

export interface ProductImageItemState {
  imageLoaded: boolean;
}

export enum ProductImageDataHook {
  ProductImage = 'ProductImageDataHook.ProductImage',
}

@withGlobalProps
export class ProductImage extends React.Component<ProductImageItemProps, ProductImageItemState> {
  public render(): JSX.Element {
    const {
      imageMode,
      mediaItem,
      targetHeight,
      targetWidth,
      imageLoaded,
      productName,
      fluid,
      stretchImage,
      globals: {isSEO},
    } = this.props;

    return (
      <WowImage
        data-hook={ProductImageDataHook.ProductImage}
        src={mediaItem.url}
        onLoad={imageLoaded}
        stretchImage={stretchImage}
        isSEOBot={isSEO}
        alt={mediaItem.altText || productName}
        sourceWidth={mediaItem.width}
        sourceHeight={mediaItem.height}
        width={targetWidth}
        height={targetHeight}
        loadingBehavior={ImageLoadingBehaviorOptions.none}
        resize={imageMode === 1 ? ImageResizeOptions.cover : ImageResizeOptions.contain}
        shouldUseLQIP={true}
        fluid={fluid}
      />
    );
  }
}
