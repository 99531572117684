import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductName} from '../../ProductName/ProductName';

import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {LayoutComponentProps} from '../../../types/app-types';
import {convertCssValueToConfig} from '../../../commons/utils';

import s from './StunningLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';

export const StunningLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings, globals}) => {
  const {addReviewsSlotsToProductPage} = globals.experiments ?? {};
  return (
    <article className={s.container}>
      {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
      <header className={s.header}>
        <Cell className={s.gallery}>
          <ProductGalleryLayout
            product={product}
            layoutConfig={createLayoutConfigWithDefaults({
              withDynamicHeight: true,
              dimensions: {
                mainMedia: {
                  widthConf: {num: 100, unit: '%'},
                  heightConf: convertCssValueToConfig(s.sharedStyleVariables_headerMinHeight),
                },
                thumbnails: {
                  widthConf: {num: 100, unit: '%'},
                  heightConf: {num: 50, unit: 'px'},
                },
              },
            })}
            {...settings}
          />
        </Cell>
        <Cell className={s.floatSectionWrapper}>
          <section className={s.floatSection}>
            <Cell className={s.title}>
              <ProductName name={product.name} />
            </Cell>
            {addReviewsSlotsToProductPage && <RatingSummarySlot />}
            {settings.shouldShowSku && (
              <Cell className={s.sku}>
                <ProductSku />
              </Cell>
            )}
            {settings.shouldShowPrice && (
              <Cell className={s.price}>
                <ProductPrice />
              </Cell>
            )}

            {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
              <Cell>
                <ProductDiscountName name={product.itemDiscount.discountRuleName} />
              </Cell>
            )}

            {settings.shouldShowProductPaymentBreakdown && (
              <Cell>
                <ProductPaymentBreakdown />
              </Cell>
            )}

            <Cell>
              <DetailsPlaceholder />
            </Cell>
            <Cell className={s.options}>
              <ProductOptions
                shouldShowQuantity={settings.shouldShowQuantity}
                shouldShowStockIndicator={settings.shouldShowStockIndicator}
              />
              <ProductPageButtonsContainer />
            </Cell>
            {settings.shouldShowSocialNetwork && (
              <Cell className={s.social}>
                <ProductSocial />
              </Cell>
            )}
          </section>
        </Cell>
      </header>
      <section className={s.containerDescription}>
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
      </section>
      <section className={s.containerInfoSection}>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
      </section>
      <Cell>
        {addReviewsSlotsToProductPage && <ReviewsSlot />}
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
