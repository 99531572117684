import {ModalGalleryBase, PinchType, Zoom, ZoomDPR, ZoomDirection} from './ModalGalleryBase';
import {preventDefault} from './lib/utils';

export class ModalGalleryMobile extends ModalGalleryBase {
  protected taps: NodeJS.Timer;
  protected touchHypot: number[] = [0];
  protected direction: ZoomDirection = ZoomDirection.MOBILE;
  protected dpr: ZoomDPR = ZoomDPR.MOBILE;

  protected bindEvents() {
    this.root.current.addEventListener('touchstart', preventDefault, {passive: false});
  }

  protected unbindEvents() {
    this.root.current.removeEventListener('touchstart', preventDefault);
  }

  protected get margins() {
    return {y: 120, x: 0};
  }

  protected get gestures() {
    return {
      onTouchStart: this.handleOnTouchStart,
      onTouchMove: this.handleOnTouchMove,
      onTouchEnd: (e: React.TouchEvent) => {
        this.handleOnTouchEnd(e);
        this.handleTap();
      },
    };
  }

  private readonly handleOnTouchStart = (event: React.TouchEvent) => {
    this.touchHypot = event.touches.length > 1 ? [this.calcHypot(event.touches)] : [0];
    this.currentAxis = {
      x: event.touches[0].clientX + this.state.zoomOffset.x,
      y: event.touches[0].clientY + this.state.zoomOffset.y,
    };
  };

  private calcHypot(touches: React.TouchList) {
    return Math.hypot(touches[0].clientX - touches[1].clientX, touches[0].clientY - touches[1].clientY);
  }

  private get pinchType(): PinchType {
    return this.touchHypot[this.touchHypot.length - 1] > this.touchHypot[0] ? PinchType.IN : PinchType.OUT;
  }

  protected readonly handleOnTouchMove = (event: React.TouchEvent) => {
    if (this.touchHypot[0] && event.changedTouches.length > 1) {
      this.touchHypot = [...this.touchHypot, this.calcHypot(event.changedTouches)];
    }
    if (!this.isZoomed || this.touchHypot[0]) {
      return;
    }
    this.setAxis({x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY});
  };

  protected readonly handleTap = (): void => {
    if (!this.isSingleTouch || this.currentMedia.mediaType !== 'PHOTO') {
      return;
    }
    if (this.taps) {
      this.resetTaps();
      this.setZoom(this.isZoomed ? Zoom.OFF : Zoom.ON);
    } else {
      this.taps = setTimeout(this.resetTaps, 300);
    }
  };

  protected get isSingleTouch() {
    return this.touchHypot[0] === 0;
  }

  protected readonly resetTaps = () => {
    clearTimeout(this.taps);
    this.taps = null;
  };

  private readonly handleOnTouchEnd = (event: React.TouchEvent) => {
    const momentumX = this.currentAxis.x - event.changedTouches[0].clientX;
    const momentumY = this.currentAxis.y - event.changedTouches[0].clientY;
    const isPinch = this.touchHypot[0];
    const isZoomed = this.isZoomed;
    const isVerticalSwipe = momentumY >= 150 || momentumY <= -150;
    const isSwipeLeft = momentumX >= 50;
    const isSwipeRight = momentumX <= -50;

    if (isPinch) {
      return this.setZoom(this.pinchType === PinchType.IN ? Zoom.ON : Zoom.OFF);
    }
    if (isZoomed) {
      return;
    }
    if (isVerticalSwipe) {
      return this.props.handleClose();
    }
    if (isSwipeLeft) {
      return this.navigateNext();
    }
    if (isSwipeRight) {
      return this.navigatePrev();
    }
  };
}
