import React from 'react';
import s from './TextOption.scss';
import classNames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {UserInputType} from '../../../constants';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';

export enum DataHook {
  TextOption = 'text-option',
  Title = 'text-option-title',
  TextArea = 'text-option-text-area',
  RemainLength = 'text-option-remain-length',
}

export interface TextOptionProps extends IProvidedTranslationProps, ProvidedGlobalProps {
  handleOnChange(textAreaElemValue: string): void;
  isRequired: boolean;
  maxLength: number;
  title: string;
  value: string;
  errorIndex: number;
}

export interface TextOptionState {
  isFocused: boolean;
  remainCount: number;
  value: string;
}

@withGlobalProps
@withTranslations('globals.texts')
export class TextOption extends React.Component<TextOptionProps & IProvidedTranslationProps, TextOptionState> {
  private readonly textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  public state = {value: this.props.value, remainCount: this.props.maxLength, isFocused: false};

  private readonly onFocus = () => {
    this.focusTextArea();
    this.setState({isFocused: true});
  };

  private readonly focusTextArea = () => {
    this.textAreaRef.current.focus();
  };

  private readonly onInputChange = (event) => {
    const textAreaElemValue: string = event.target.value;

    this.setState({
      value: textAreaElemValue,
      remainCount: this.props.maxLength - textAreaElemValue.length,
    });
  };

  private readonly onBlur = (event) => {
    this.props.handleOnChange(event.target.value);
  };

  public render(): JSX.Element {
    const {
      title,
      isRequired,
      maxLength,
      t,
      errorIndex,
      globals: {errorPlacement, isProductSubmitted, userInputErrors},
    } = this.props;

    const {remainCount, value, isFocused} = this.state;

    const textAreaClasses = classNames(
      s.textOptionTextArea,
      {[s.midHeight]: isFocused && maxLength > 25 && maxLength < 250},
      {[s.fullHeight]: isFocused && maxLength > 250}
    );

    let caption = `${title}`;
    if (!isRequired) {
      caption += ` (${t('OPTIONAL')})`;
    }

    return (
      <div data-hook={DataHook.TextOption} className={s.textOption}>
        <label data-hook={DataHook.Title} className={s.textOptionInputLabel}>
          {caption}
        </label>
        <ErrorTooltipProvider
          className={s.textOptionErrorTooltip}
          content={t('REQUIRED_FIELD')}
          placement={errorPlacement}
          show={isProductSubmitted && userInputErrors[UserInputType.Text][errorIndex]}>
          <div className={s.textAreaContainer}>
            <textarea
              data-hook={DataHook.TextArea}
              ref={this.textAreaRef}
              maxLength={maxLength}
              value={value}
              className={textAreaClasses}
              onFocus={this.onFocus}
              onChange={this.onInputChange}
              onBlur={this.onBlur}
            />
            <span aria-hidden="true" className={s.remainCount} data-hook={DataHook.RemainLength}>
              {remainCount}
            </span>
          </div>
        </ErrorTooltipProvider>
      </div>
    );
  }
}
