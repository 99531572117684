import * as React from 'react';
import {withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './EmptyState.scss';
import {Navigation} from '../../Navigation/Navigation';
import {Cell} from '../Cell/Cell';

@withGlobalProps
@withTranslations('globals.texts')
export class EmptyState extends React.Component<LayoutComponentProps & IProvidedTranslationProps> {
  public render() {
    const {t} = this.props;
    const homepage = this.props.globals.pagePath[0];

    return (
      <div className={s.container}>
        <Cell className={s.navigation}>
          <Navigation renderPagination={false} />
        </Cell>
        <Cell className={s.headline} data-hook="empty-state-headline">
          {t('productPage.error.message')}
        </Cell>
        <Cell className={s.continue}>
          <a data-hook="empty-state-continue-shopping" href={homepage?.url}>
            {t('productPage.error.CTA')}
          </a>
        </Cell>
      </div>
    );
  }
}
