import * as React from 'react';
import * as Icon from '../../../../../icons/dist';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import s from './TabArrow.scss';
import {ArrowsDir} from '../../../../../constants';

export enum DataHook {
  TabsInfoSection = 'tabs-info-section',
  Body = 'tabs-info-section-body',
  ArrowLeft = 'tabs-arrow-left',
  ArrowRight = 'tabs-arrow-right',
}

export interface TabArrowProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  direction: ArrowsDir;
  arrowStyle: {width: number};
  handleKeypressScroll(e: React.KeyboardEvent<HTMLButtonElement>): void;
  handleScrollClick(): void;
}

@withGlobalProps
@withTranslations('globals.texts')
export class TabArrow extends React.Component<TabArrowProps> {
  public render(): JSX.Element {
    const {direction, arrowStyle, handleKeypressScroll, handleScrollClick, t} = this.props;
    const iconContainerClass = classNames(
      s.iconContainer,
      {[s.leftArrowButton]: direction === ArrowsDir.LEFT},
      {[s.rightArrowButton]: direction === ArrowsDir.RIGHT}
    );
    const btnClass = classNames(s.icon);
    return (
      <div style={arrowStyle} className={iconContainerClass}>
        <button
          type="button"
          onKeyPress={handleKeypressScroll}
          className={btnClass}
          onClick={handleScrollClick}
          data-hook={direction === ArrowsDir.LEFT ? DataHook.ArrowLeft : DataHook.ArrowRight}
          aria-label={direction === ArrowsDir.LEFT ? t('GALLERY_PREVIOUS') : t('GALLERY_NEXT')}>
          {direction === 'left' ? <Icon.ArrowLeft /> : <Icon.ArrowRight />}
        </button>
      </div>
    );
  }
}
