import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import s from './ViewMore.scss';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {compose} from '../../commons/utils';

function ViewMoreComponent({t, globals: {navigate, product}}: ProvidedGlobalProps & IProvidedTranslationProps) {
  return (
    <a data-hook={'quick-view-more'} className={s.root} onClick={() => navigate(product.urlPart, true)}>
      {t('productPage.quickView.viewProductDetails')}
    </a>
  );
}

export const ViewMore = compose(withTranslations('globals.texts'), withGlobalProps)(ViewMoreComponent);
