import React from 'react';
import {classes} from './WishlistButton.st.css';
import wishlistIconButtonStyle from './WishlistIconButton.scss';
import {Button, StatesButton, StatesButtonStates} from 'wix-ui-tpa';
import {LikeHeart, LikeHeartFill} from '@wix/wix-ui-icons-common/on-stage';
import classNames from 'classnames';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ActionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';

export enum Mode {
  INLINE,
  FLOATING,
}

export interface WishlistButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  mode: Mode;
}

@withGlobalProps
@withTranslations('globals.texts')
export class WishlistButton extends React.Component<WishlistButtonProps> {
  private get isFloatingMode() {
    const {mode} = this.props;

    return mode === Mode.FLOATING;
  }

  private readonly handleWishlistButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const {handleWishlistButtonClick} = this.props.globals;
    handleWishlistButtonClick();
  };

  private getButtonAriaLabel() {
    const {
      t,
      globals: {productWasAddedToWishlist},
    } = this.props;

    return productWasAddedToWishlist ? t('sr.wishlist.remove.button') : t('sr.wishlist.button');
  }

  private renderIconButton() {
    const {
      globals: {
        productWasAddedToWishlist,
        shouldShowAddToCartButton,
        shouldShowBuyNowButton,
        shouldShowSubscribeButton,
      },
    } = this.props;

    const buttonClasses = classNames('wishlistButton', wishlistIconButtonStyle.wishlistIconButton, {
      [classes.wishlistButton]: !this.isFloatingMode,
      [classes.wishlistButtonFloatingMode]: this.isFloatingMode,
      [wishlistIconButtonStyle.floatingMode]: this.isFloatingMode,
      [wishlistIconButtonStyle.wishlistWithAddToCart]: !shouldShowSubscribeButton && shouldShowAddToCartButton,
      [wishlistIconButtonStyle.wishlistWithDirectPurchaseButton]:
        shouldShowSubscribeButton || (!shouldShowAddToCartButton && shouldShowBuyNowButton),
    });

    return (
      <Button
        type="button"
        onClick={this.handleWishlistButtonClick}
        className={buttonClasses}
        data-hook={'wishlist-button'}
        aria-label={this.getButtonAriaLabel()}
        aria-pressed={productWasAddedToWishlist}>
        <i className={wishlistIconButtonStyle.iconContainer} data-hook={'wishlist-button-icon-container'}>
          {productWasAddedToWishlist ? (
            <LikeHeartFill
              className={classNames(wishlistIconButtonStyle.heartIcon, wishlistIconButtonStyle.filled)}
              data-hook={'wishlist-button-icon'}
            />
          ) : (
            <LikeHeart
              className={classNames(wishlistIconButtonStyle.heartIcon, wishlistIconButtonStyle.empty)}
              data-hook={'wishlist-button-icon'}
            />
          )}
        </i>
      </Button>
    );
  }

  private renderFullWidthButton() {
    const {
      t,
      globals: {productWasAddedToWishlist, wishlistActionStatus, resetWishlistStatus},
    } = this.props;

    const caption = productWasAddedToWishlist ? t('wishlist.remove.button') : t('wishlist.button');

    return (
      <StatesButton
        state={wishlistActionStatus === ActionStatus.SUCCESSFUL ? StatesButtonStates.SUCCESS : StatesButtonStates.IDLE}
        idleContent={caption}
        onNotificationEnd={() => resetWishlistStatus()}
        onClick={this.handleWishlistButtonClick}
        className={classes.wishlistFullWidthButton}
        disabled={false}
        fullWidth
        data-hook={'wishlist-states-button'}
      />
    );
  }

  private shouldRenderIconButton() {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton, shouldShowSubscribeButton},
    } = this.props;

    return shouldShowAddToCartButton || shouldShowBuyNowButton || shouldShowSubscribeButton || this.isFloatingMode;
  }

  public render(): JSX.Element {
    return this.shouldRenderIconButton() ? this.renderIconButton() : this.renderFullWidthButton();
  }
}
