import React from 'react';
import s from './InfoSectionDescription.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {SanitizeInnerHTMLWrapper} from '@wix/wixstores-client-common-components/dist/es/src/SanitizeInnerHTMLWrapper';

export enum DataHook {
  Description = 'info-section-description',
}
export interface InfoSectionDescriptionProps extends ProvidedGlobalProps {
  description: string;
  index: number;
}

@withGlobalProps
export class InfoSectionDescription extends React.Component<InfoSectionDescriptionProps> {
  public render(): JSX.Element {
    const {description, index} = this.props;

    return (
      <SanitizeInnerHTMLWrapper innerHtml={description}>
        <div key={index} data-hook={DataHook.Description} className={s.description} />
      </SanitizeInnerHTMLWrapper>
    );
  }
}
