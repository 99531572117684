import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {bootstrapAppNoCss} from '../../commons/bootstrapAppNoCss';
import {ProductPageApp} from './ProductPageApp';
import {PRODUCT_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';

const bootstrapOptions = {
  cssPath: ['productPage.min.css', 'productPage.stylable.bundle.css'],
};

const sentryOptions = {
  dsn: PRODUCT_PAGE_DSN,
  config: {environment: 'Native Component'},
};

export const component = withSentryErrorBoundary(bootstrapAppNoCss(ProductPageApp, bootstrapOptions), sentryOptions);
