import React from 'react';
import classNames from 'classnames';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import s from './RatingSummarySlot.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {SlotIds} from '../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

@withGlobalProps
export class RatingSummarySlot extends React.Component<ProvidedGlobalProps> {
  public render() {
    const isPluginInstalled = !!this.props.globals.slots?.[SlotIds.ProductPageDetailsSlot1];
    return (
      <Cell className={classNames(isPluginInstalled ? s.spacing : '', s.root)}>
        <SlotsPlaceholder slotId={SlotIds.ProductPageDetailsSlot1} />
      </Cell>
    );
  }
}
