import React from 'react';
import cx from 'classnames';
import s from './ResponsiveLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {Cell} from '../Cell/Cell';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {Mode, WishlistButton} from '../../ProductPageButtonsContainer/WishlistButton/WishlistButton';
import {Navigation, NavigationType} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {ProductName} from '../../ProductName/ProductName';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ResponsiveGallery} from './ResponsiveGallery/ResponsiveGallery';
import {ShowOnMobileOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnMobileOnly/ShowOnMobileOnly';
import {withGlobalProps} from '../../../providers/globalPropsProvider';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';

@withGlobalProps
export class ResponsiveLayout extends React.Component<LayoutComponentProps> {
  private renderLeftCol() {
    const {
      product,
      settings,
      globals: {shouldShowWishlistButton},
    } = this.props;
    return (
      <section className={cx(s.col, s.left)}>
        <Cell className={s.gallery}>
          <ResponsiveGallery
            key={product.media.length > 0 ? product.media[0].id : 'no-media'}
            media={product.media}
            productName={product.name}
            imageMode={settings.imageMode}
            imageRatio={settings.imageRatioId}
          />
          {shouldShowWishlistButton && (
            <ShowOnMobileOnly>
              <WishlistButton mode={Mode.FLOATING} />
            </ShowOnMobileOnly>
          )}
        </Cell>
      </section>
    );
  }

  private renderRightCol() {
    const {product, settings, globals} = this.props;
    const {addReviewsSlotsToProductPage} = globals.experiments ?? {};

    return (
      <section className={cx(s.col, s.right)}>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}
        <Cell className={s.name}>
          <ProductName name={product.name} />
        </Cell>
        {addReviewsSlotsToProductPage && <RatingSummarySlot />}
        {settings.shouldShowPrice && (
          <Cell className={s.price}>
            <ProductPrice />
          </Cell>
        )}

        {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
          <Cell>
            <ProductDiscountName name={product.itemDiscount.discountRuleName} />
          </Cell>
        )}

        {settings.shouldShowProductPaymentBreakdown && (
          <Cell>
            <ProductPaymentBreakdown />
          </Cell>
        )}

        <Cell>
          <DetailsPlaceholder />
        </Cell>
        <Cell className={s.options}>
          <ProductOptions
            shouldShowQuantity={settings.shouldShowQuantity}
            shouldShowStockIndicator={settings.shouldShowStockIndicator}
          />{' '}
          <ProductPageButtonsContainer />
        </Cell>
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    );
  }

  public render(): JSX.Element {
    const {isMobile, experiments} = this.props.globals;
    const renderNavigation = this.props.settings.shouldShowNavigation || isMobile;
    const navigationType = isMobile ? NavigationType.Short : NavigationType.Long;

    return (
      <article className={s.container}>
        {renderNavigation && <Navigation className={s.navigation} renderBreadcrumbs={isMobile} type={navigationType} />}
        <div className={s.main}>
          {this.renderLeftCol()}
          {this.renderRightCol()}
        </div>
        <Cell>
          {experiments?.addReviewsSlotsToProductPage && <ReviewsSlot />}
          <BottomPlaceholder />
        </Cell>
      </article>
    );
  }
}
