import {ISubscriptionPlan} from './SubscriptionPlan';

type TranslationFunction = (string: any, values?: {}, fallback?: string) => string;

const getIntervalSingleTranslation = ({
  t,
  frequencyUnitForKeys,
  duration,
}: {
  t: TranslationFunction;
  frequencyUnitForKeys: string;
  duration: ISubscriptionPlan['duration'];
}): string => {
  if (!duration) {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequencySingular.untilCanceled.label`);
  } else if (duration === 2) {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequencySingular.duration2.label`);
  } else {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequencySingular.durationPlural.label`, {
      numberOfDurationUnits: duration,
    });
  }
};

const getInterval2Translation = ({
  t,
  frequencyUnitForKeys,
  duration,
}: {
  t: TranslationFunction;
  frequencyUnitForKeys: string;
  duration: ISubscriptionPlan['duration'];
}): string => {
  if (!duration) {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequency2.untilCanceled.label`);
  } else {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequency2.durationPlural.label`, {
      numberOfDurationUnits: duration,
    });
  }
};

const getIntervalPluralTranslation = ({
  t,
  interval,
  frequencyUnitForKeys,
  duration,
}: {
  t: TranslationFunction;
  interval: ISubscriptionPlan['interval'];
  frequencyUnitForKeys: string;
  duration: ISubscriptionPlan['duration'];
}): string => {
  if (!duration) {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequencyPlural.untilCanceled.label`, {
      numberOfFrequencyUnits: interval,
    });
  } else {
    return t(`productPage.planDescription.${frequencyUnitForKeys}.frequencyPlural.durationPlural.label`, {
      numberOfFrequencyUnits: interval,
      numberOfDurationUnits: duration,
    });
  }
};

export const getSubscriptionDetailsTranslation = ({
  t,
  plan,
}: {
  t: TranslationFunction;
  plan: ISubscriptionPlan;
}): string => {
  const {interval, frequency, duration} = plan;
  if (frequency === 'DAY') {
    return 'daily for QA';
  }
  const frequencyUnitForKeys = `${frequency.toLowerCase()}s`;
  switch (interval) {
    case 1:
      return getIntervalSingleTranslation({t, frequencyUnitForKeys, duration});
    case 2:
      return getInterval2Translation({t, frequencyUnitForKeys, duration});
    default:
      return getIntervalPluralTranslation({t, interval, frequencyUnitForKeys, duration});
  }
};
