import React from 'react';
import s from './NumberInputSpinner.scss';
import classnames from 'classnames';
import {UserInputType} from '../../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';

export interface NumberInputSpinnerProps {
  isFocused?: boolean;
  max?: number;
  min?: number;
  step?: number;
  title?: string;
  value: number;
  onChange(value: number): void;
}

const NumberInputSpinnerDefaultProps: Partial<NumberInputSpinnerProps> = {
  isFocused: false,
  max: Infinity,
  min: 1,
  step: 1,
  title: '',
};

@withGlobalProps
@withTranslations('globals.texts')
export class NumberInputSpinner extends React.Component<
  NumberInputSpinnerProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  public numberInputSpinnerRef = React.createRef<HTMLInputElement>();
  public static defaultProps = NumberInputSpinnerDefaultProps;

  public componentDidMount(): void {
    if (this.props.isFocused) {
      this.focusNumberInputSpinner();
    }
  }

  private readonly focusNumberInputSpinner = () => {
    this.numberInputSpinnerRef.current.focus();
  };

  private readonly onUp = () => {
    const {value, max, step, onChange} = this.props;
    const nextValue = Math.min(value + step, max);
    onChange(nextValue);
  };

  private readonly onDown = () => {
    const {value, min, step, onChange} = this.props;
    const nextValue = Math.max(value - step, min);
    onChange(nextValue);
  };

  private readonly onChange = (e) => {
    const {value: currentValue, onChange} = this.props;
    const userInputValue = Number(e.target.value);
    const nextValue = isNaN(userInputValue) || e.target.value === '' ? currentValue : userInputValue;
    onChange(nextValue);
  };

  public render(): JSX.Element {
    const {title, max, min, value} = this.props;
    const {
      t,
      globals: {userInputs, quantityRange, errorPlacement, isProductSubmitted, userInputErrors},
    } = this.props;

    const quantity = userInputs[UserInputType.Quantity][0];

    let error = null;
    if (quantity > quantityRange.max) {
      error = this.props.globals.experiments.CartTooltipWithoutNumber
        ? t('QUANTITY_EXCEEDS_INVENTORY_NO_NUMBER')
        : t('QUANTITY_EXCEEDS_INVENTORY', {inventory: quantityRange.max});
    } else {
      error = t('QUANTITY_BELOW_MINIMUM_RANGE', {minimum: quantityRange.min});
    }

    return (
      <div>
        <div>
          <ErrorTooltipProvider
            content={error}
            placement={errorPlacement}
            show={isProductSubmitted && userInputErrors[UserInputType.Quantity][0]}>
            <div>
              <label>
                <span data-hook="number-input-spinner-title" className={s.label}>
                  {title}
                </span>
                <div className={s.inputGroup} data-hook="number-input-spinner-container">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    data-hook="number-input-spinner-input"
                    value={value}
                    aria-label={title}
                    onChange={this.onChange}
                    ref={this.numberInputSpinnerRef}
                    max={max}
                    min={min}
                  />

                  <div data-hook="number-input-spinner-arrows-container" className={s.spinnerArrows} aria-hidden={true}>
                    <span
                      data-hook="number-input-spinner-up-arrow"
                      className={classnames(s.upArrow, {[s.disabled]: value >= max})}
                      onClick={this.onUp}
                    />
                    <span
                      data-hook="number-input-spinner-down-arrow"
                      className={classnames(s.downArrow, {[s.disabled]: value <= min})}
                      onClick={this.onDown}
                    />
                  </div>
                </div>
              </label>
            </div>
          </ErrorTooltipProvider>
        </div>
      </div>
    );
  }
}
